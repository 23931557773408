@use 'src/src/app/scss/abstracts' as *;

.auth-modal {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  position: fixed;
  z-index: $z-index-modal;
  inset: 0;
  background: linear-gradient(2deg, #7645ff 1.32%, #0a0614 31.56%);
  transform: translate3d(0, -140%, 0);
  transition:
    transform $transition-slow,
    opacity $transition-slow;
  overscroll-behavior: contain;
  overflow-y: auto;

  // opacity: 0;

  &__body {
    overflow: hidden;
    padding-top: 50px;
    padding-bottom: 50px;

    @include mq($until: md) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &__body-inner {
    max-width: 535px;
    margin-left: auto;
    margin-right: auto;
  }

  &__logo {
    @include mq($until: lg) {
      width: 106px;
      height: 27px;
    }
  }

  &__top {
    @include reset-text-indents;

    position: sticky;
    z-index: $z-index-other;
    top: 0;
    padding-top: 14px;
    background-color: $color-other-body;
    padding-bottom: 14px;
  }

  &__top-inner {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  &__button-close {
    flex: 0 0 auto;
    position: relative;
    z-index: $z-index-modal;
    transition: opacity $transition-fast;
    margin-top: -2px;

    @include mq($until: lg) {
      margin-top: -5px;
      margin-right: -4px;
    }

    button {
      @include reset-btn;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      transform: rotate(45deg);
      color: $color-base-gray-500;
      transition: color $transition-fast;

      &:hover {
        color: $color-base-purple-200;
      }

      &:active {
        color: $color-base-gray-500;
      }

      svg {
        stroke: currentColor;
      }
    }
  }

  &--is-open {
    transform: translate3d(0, 0, 0);

    // opacity: 1;
  }
}
